import * as React from 'react'
import Layout from '../../../../../layout'
import CreateTeamPage from '../../../../../pagesComponents/groups/create-team'

const UpdateTeam = ({ id, teamId }) => {
	return (
		<Layout title="Team Updated">
			<CreateTeamPage id={id} teamId={teamId} />
		</Layout>
	)
}

export default UpdateTeam
